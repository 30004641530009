// Config
const API_URL = process.env.REACT_APP_API_URL || '/.netlify/functions/api'

const BASE_API_URL = `${API_URL}`

// Content
export const GET_USER_COMPANY = `${BASE_API_URL}/user/company`

export const GET_COMPANY_TERMS = () => `${BASE_API_URL}/user/company/terms`

export const GET_TODOS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/todos`

export const GET_REQUESTS = (memberId: string) =>
  `${BASE_API_URL}/user/company/members/${memberId}/requests`

export const GET_PDPS = () => `${BASE_API_URL}/user/company/pdps`

export const SET_PDP_STATE = (memberId: string, state: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/state/${state}`

export const IMPROVE_PDP = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/improve`

export const MODIFY_PDP_GOAL = (memberId: string, goalId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals/${goalId}/submissions`

export const AUTH_USER_DETAILS = (slug?: string) =>
  `${BASE_API_URL}/users/auth${slug ? `?selectedCompanySlug=${slug}` : ``}`

export const USER_LOGIN = `${BASE_API_URL}/users/auth/google`
export const MICROSOFT_LOGIN = `${BASE_API_URL}/users/auth/microsoft`

export const SAVE_USER = () => `${BASE_API_URL}/user/company/members`

export const EDIT_USER = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}`

export const DISABLE_USER = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/disable`

export const ADD_OR_UPDATE_SKILL = () => `${BASE_API_URL}/user/company/skills`

export const GET_PDP_BY_USER = (
  memberId: string,
  termNumber: number | 'latest'
) => `${BASE_API_URL}/members/${memberId}/term/${termNumber}/pdp`

export const EDIT_COMPANY_NAME = `${BASE_API_URL}/user/company/properties/name`

export const REFRESH_TOKEN = `${BASE_API_URL}/users/auth/refresh`

export const ADD_TEAM = () => `${BASE_API_URL}/user/company/teams`

export const EDIT_TEAM = (teamId: string) =>
  `${BASE_API_URL}/user/company/teams/${teamId}`

export const UPDATE_TEAM_LEVELS = (teamId: string) =>
  `${BASE_API_URL}/user/company/teams/${teamId}/levels`

export const DELETE_TODO = (memberId: string, todoId: string) =>
  `${BASE_API_URL}/members/${memberId}/todos/${todoId}`

export const ADD_NEW_GOAL = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals`

export const SUBMIT_GOAL_MESSAGE = (memberId: string, goalId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals/${goalId}/messages`

export const MARK_GOAL_MESSAGE_AS_OPENED = (pdpId: string, goalId: string) =>
  `${BASE_API_URL}/pdps/${pdpId}/goals/${goalId}/messages/open`

export const SUBMIT_CUSTOM_LEAD = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/leads`

export const UPDATE_MEMBER_BUDDIES = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/buddies`

export const SET_GOAL_APPROVED_STATUS = (memberId: string, goalId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals/${goalId}/approve`

export const REMOVE_TEAM = (teamId: string) =>
  `${BASE_API_URL}/user/company/teams/${teamId}`

export const DELETE_SKILL_FROM_COMPANY = () =>
  `${BASE_API_URL}/user/company/skills/delete`

export const DELETE_GOAL_FROM_PDP = (memberId: string, goalId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals/${goalId}/delete`

export const PIN_GOAL = (memberId: string, goalId: string) =>
  `${BASE_API_URL}/members/${memberId}/pdp/goals/${goalId}/pin`

export const CREATE_CULTURE_EVALUATIONS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/culture-evaluations/create`

export const SPECIFY_CLOSE_MEMBERS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/close-members`

export const GET_COMPANY_CULTURE_EVALUATION_STATISTICS = (termNumber: number) =>
  `${BASE_API_URL}/user/company/terms/${termNumber}/culture-evaluations/statistics`

export const GET_COMPANY_FEEDBACK_AGGREGATIONS = () =>
  `${BASE_API_URL}/user/company/feedbacks/numbers`

export const GET_MEMBER_FEEDBACK_DETAILS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/statistics`

export const GET_AUTH_USER_FEEDBACK_DETAILS = () =>
  `${BASE_API_URL}/user/auth/feedbacks/statistics`

export const GET_AUTH_USER_CULTURE_EVALUATION_DETAILS = () =>
  `${BASE_API_URL}/user/auth/culture-evaluations/statistics`

export const GET_COMPANY_METADATA = () =>
  `${BASE_API_URL}/user/company/metadata`

export const GET_SAVED_FEEDBACK = (feedbackId: string) =>
  `${BASE_API_URL}/feedbacks/${feedbackId}`

export const SUBMIT_PARTIAL_FEEDBACK_FORM = (feedbackId: string) =>
  `${BASE_API_URL}/feedbacks/${feedbackId}/partialfeedback`

export const SUBMIT_FEEDBACK_FORM = (feedbackId: string) =>
  `${BASE_API_URL}/feedbacks/${feedbackId}`

export const DELETE_FEEDBACK = (feedbackId: string) =>
  `${BASE_API_URL}/feedbacks/${feedbackId}`

export const GET_MEMBER_FEEDBACK = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks`

export const GET_ALL_MEMBER_FEEDBACK = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/all`

export const GET_MEMBER_FEEDBACK_HIGHLIGHTS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/highlights`

export const GET_MEMBER_COMPETENCY_STATISTICS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/competencies/statistics`

export const FINALIZE_FEEDBACK_PLANNING = () =>
  `${BASE_API_URL}/user/company/feedbacks/finalize-planning`

export const SUBMIT_PARTIAL_CULTURE_EVALUATION_FORM = (
  cultureEvaluationId: string
) =>
  `${BASE_API_URL}/culture-evaluations/${cultureEvaluationId}/partial-culture-evaluation`

export const SUBMIT_CULTURE_EVALUATION_FORM = (cultureEvaluationId: string) =>
  `${BASE_API_URL}/culture-evaluations/${cultureEvaluationId}`

export const DELETE_CULTURE_EVALUATION = (cultureEvaluationId: string) =>
  `${BASE_API_URL}/culture-evaluations/${cultureEvaluationId}`

export const GET_CULTURE_EVALUATION = (cultureEvaluationId: string) =>
  `${BASE_API_URL}/culture-evaluations/${cultureEvaluationId}`

export const GET_SAVED_EVALUATION = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}`

export const SKIP_EVALUATION = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}`

export const GET_MEMBER_EVALUATIONS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/evaluations`

export const SAVE_PARTIAL_SKILL_EVALUATION = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}/skills`

export const SAVE_PARTIAL_GOAL_EVALUATION = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}/goals`

export const SAVE_FULL_EVALUATION = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}/submit`

export const UPDATE_EVALUTION_SUBMITTED_PARTS = (evaluationId: string) =>
  `${BASE_API_URL}/evaluations/${evaluationId}/status`

export const GET_MEMBER_COLLECTIONS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/collections`

export const GET_SINGLE_COLLECTION = (collectionId: string) =>
  `${BASE_API_URL}/collections/${collectionId}`

export const SUBMIT_COLLECTION_NOTE = (collectionId: string) =>
  `${BASE_API_URL}/collections/${collectionId}/notes`

export const DELETE_COLLECTION_NOTE = (collectionId: string, noteId: string) =>
  `${BASE_API_URL}/collections/${collectionId}/notes/${noteId}`

export const WRAP_UP_COLLECTION = (collectionId: string) =>
  `${BASE_API_URL}/collections/${collectionId}/wrap-up`

export const GET_COMPANY_TERM_EVALUATION_STATE = (termId: string) =>
  `${BASE_API_URL}/user/company/evaluations/${termId}`

export const GET_EVALUATIONS_FOR_LEAD = (teamId: string, termId: string) =>
  `${BASE_API_URL}/teams/${teamId}/evaluations/${termId}`

export const GET_EXTERNAL_FEEDBACK = (externalFeedbackId: string) =>
  `${BASE_API_URL}/external-feedback/${externalFeedbackId}`

export const SUBMIT_EXTERNAL_FEEDBACK = (externalFeedbackId: string) =>
  `${BASE_API_URL}/external-feedback/${externalFeedbackId}`

export const REQUEST_INTERNAL_FEEDBACK = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/request-internal`

export const REQUEST_EXTERNAL_FEEDBACK = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/feedbacks/request-external`

export const REQUEST_GOALS_REVIEW = (pdpId: string) =>
  `${BASE_API_URL}/pdps/${pdpId}/reviewers`

export const CHANGE_PDP_REVIEW_STATUS = (pdpId: string) =>
  `${BASE_API_URL}/pdps/${pdpId}/review/status`

export const GET_PREVIOUS_TERM_PDP = (pdpId: string) =>
  `${BASE_API_URL}/pdps/${pdpId}/previous-pdp`

export const SUPPORT_EMAIL = (memberId?: string) =>
  `${BASE_API_URL}/support/${memberId}`

export const DELETE_LEVEL = () => `${BASE_API_URL}/company/levels/delete`

export const CREATE_ONBOARDING_TEMPLATE = `${BASE_API_URL}/onboarding-templates`

export const EDIT_ONBOARDING_TEMPLATE_NAME = (onboardingId: string) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}`

export const GET_COMPANY_ONBOARDING_TEMPLATES_OVERVIEW = `${BASE_API_URL}/onboarding-templates`

export const ADD_ONBOARDING_TEMPLATE_CHAPTER = (onboardingId: string) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters`

export const EDIT_ONBOARDING_TEMPLATE_CHAPTER_ACTIVITY_ORDER = (
  onboardingId: string,
  chapterId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters/${chapterId}`

export const REMOVE_ONBOARDING_TEMPLATE_CHAPTER = (
  onboardingId: string,
  chapterId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters/${chapterId}`

export const ADD_ONBOARDING_TEMPLATE_CHAPTER_ACTIVITY = (
  onboardingId: string,
  chapterId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters/${chapterId}/activities`

export const REMOVE_ONBOARDING_TEMPLATE_CHAPTER_ACTIVITY = (
  onboardingId: string,
  chapterId: string,
  activityId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters/${chapterId}/activities/${activityId}`

export const EDIT_ONBOARDING_TEMPLATE_CHAPTER_ACTIVITY = (
  onboardingId: string,
  chapterId: string,
  activityId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/chapters/${chapterId}/activities/${activityId}`

export const GET_ONBOARDING_TEMPLATE_CHAPTER_ACTIVITY = (
  onboardingId: string,
  activityId: string
) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/activities/${activityId}`

export const REMOVE_ONBOARDING_TEMPLATE = (onboardingId: string) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}`

export const GET_ONBOARDING_TEMPLATE = (onboardingId: string) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}`

export const DUPLICATE_ONBOARDING_TEMPLATE = (onboardingId: string) =>
  `${BASE_API_URL}/onboarding-templates/${onboardingId}/duplicate`

export const GET_INSIGHTS_STATISTICS = () =>
  `${BASE_API_URL}/insights/statistics`

export const GET_INSIGHTS_TRENDS = (termNumber: number) =>
  `${BASE_API_URL}/insights/trends/term/${termNumber}`

export const CREATE_ONBOARDING_INSTANCE = `${BASE_API_URL}/onboarding-instances`

export const DELETE_ONBOARDING_INSTANCE = (onboardingInstanceId: string) =>
  `${BASE_API_URL}/onboarding-instances/${onboardingInstanceId}`

export const GET_COMPANY_ONBOARDING_INSTANCES = (membersNumber?: number) =>
  `${BASE_API_URL}/onboarding-instances${
    membersNumber ? `?membersNumber=${membersNumber}` : ``
  }`

export const GET_ONBOARDING_INSTANCE = (instanceId: string) =>
  `${BASE_API_URL}/onboarding-instances/${instanceId}`

export const GET_ONBOARDING_INSTANCE_HIGHLIGHTS = (instanceId: string) =>
  `${BASE_API_URL}/onboarding-instances/${instanceId}/highlights`

export const GET_ONBOARDING_INSTANCE_PREVIEW = (
  instanceId: string,
  memberId: string
) => `${BASE_API_URL}/onboarding-instances/preview/${memberId}/${instanceId}`

export const UNLOCK_ONBOARDING_INSTANCE_CHAPTER = (
  instanceId: string,
  chapterId: string
) =>
  `${BASE_API_URL}/onboarding-instances/${instanceId}/chapters/${chapterId}/unlock`

export const COMPLETE_ONBOARDING_INSTANCE_STORYLINE = (instanceId: string) =>
  `${BASE_API_URL}/onboarding-instances/${instanceId}/storyline/complete`

export const CHANGE_ONBOARDING_ACTIVITY_STATUS = (
  instanceId: string,
  activityId: string
) =>
  `${BASE_API_URL}/onboarding-instances/${instanceId}/activities/${activityId}/status`

export const UPDATE_COMPANY_LIVE_MANIFESTO = (companySlug: string) =>
  `${BASE_API_URL}/company/live-manifesto/${companySlug}/update`

export const GET_COMPANY_LIVE_MANIFESTO = (companySlug: string) =>
  `${BASE_API_URL}/company/live-manifesto/${companySlug}`

export const CREATE_JOURNAL = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/create`

export const UPDATE_JOURNAL_AS_OWNER = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/updateAsOwner`

export const GET_JOURNALS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/get`

export const UPDATE_JOURNAL_PARTICIPANTS = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/participants`

export const UPDATE_JOURNAL_PREFERENCES = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/update`

export const UPDATE_JOURNAL_DATE_OPENED = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/dateOpened`

export const ADD_JOURNAL_ENTRY = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/entry`

export const DELETE_JOURNAL_ENTRY = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/entry/delete`

export const DELETE_JOURNAL = (memberId: string) =>
  `${BASE_API_URL}/members/${memberId}/journals/delete`

export const EXECUTE_SCRIPT = (
  scriptName: string,
  databaseName: string,
  withManifesto: boolean,
  withJournal: boolean,
  overrideCompany: boolean,
  isTest: boolean
) =>
  `${BASE_API_URL}/scripts/${scriptName}?databaseName=${databaseName}&isTest=${isTest}&withManifesto=${withManifesto}&withJournal=${withJournal}&overrideCompany=${overrideCompany}`

export const DROP_AND_RESTORE_SCRIPT = () =>
  `${BASE_API_URL}/scripts/dropAndRestoreDB`
